<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('double_major_application')"
                        :isColumns="true"
                        :isFilter="false"
                        :isNewButton="isNewButton&&checkPermission('doublemajor_store')"
                        :other-button="exportExcelData"
                        @new-button-click="store()"
                        @exportExcel=""
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('double_major_application')"
                              :isColumns="true"
                              :isFilter="false"
                              :other-button="exportExcelData"
                              :isNewButton="isNewButton&&checkPermission('doublemajor_store')"
                              @new-button-click="store()"
                              @exportExcel=""
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <!-- Info -->
            <div class="mb-4 d-flex">
                <b-button variant="light"
                          class="border w-40 h-40 mr-2"
                          :class="showExtraStudentInfo ? null : 'collapsed'"
                          :aria-expanded="showExtraStudentInfo ? 'true' : 'false'"
                          aria-controls="collapse-4"
                          @click="showExtraStudentInfo = !showExtraStudentInfo"
                >
                    <i class="ri-arrow-down-s-line" v-if="!showExtraStudentInfo"></i>
                    <i class="ri-arrow-up-s-line" v-if="showExtraStudentInfo"></i>
                </b-button>
                <div
                    class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                    {{ getObjectValue(student, 'student_number') }}, {{ getObjectValue(student, 'name') }}
                    {{ getObjectValue(student, 'surname') }}
                </div>
            </div>
            <b-collapse id="collapse-4" v-model="showExtraStudentInfo">
                <div class="border rounded-sm p-4 mb-4">
                    <b-row>
                        <b-col cols="auto">
                            <b-form-group :label="$t('faculty')">
                                <div class="label-as-input">
                                    {{ getObjectValue(student, 'faculty_name') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('program')">
                                <div class="label-as-input">
                                    {{ getObjectValue(student, 'program_name') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('class')">
                                <div class="label-as-input">
                                    {{ student.class ? $t('class_x', {'class': student.class}) : '-' }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('number_of_semesters')">
                                <div class="label-as-input">
                                    {{ getObjectValue(student, 'semester_count') }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('registration_type_1')">
                                <div class="label-as-input">
                                    {{
                                        student.registration_type_text || '-'
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('registration_date')">
                                <div class="label-as-input">
                                    {{ toLocaleDate(student.registration_date) }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('prep_status')">
                                <div class="label-as-input">
                                    {{ student.prep_status || '-' }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="auto">
                            <b-form-group :label="$t('equivalence_status')">
                                <div class="label-as-input">
                                    {{ student.equivalence_status || '-' }}
                                </div>
                            </b-form-group>
                        </b-col>

                        <b-col cols="auto">
                            <b-form-group :label="$t('gno')">
                                <div class="label-as-input">
                                    {{ getObjectValue(student, 'cgpa') }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>

            <!-- Datatable -->
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
                :title="$t('applications')"
            />

            <!-- Modals -->
            <CommonModal ref="preferenceFormModal"
                         :size="formProcess=='addFiles' ? 'xl':'xxl'"
                         :onHideOnlyX="true"
                         @bHideModalHeaderClose="preferenceFormClose()"
                         v-if="checkPermission('doublemajorpreference_store')">
                <template v-slot:CommonModalTitle>
                    {{ formProcess=='showFiles' ? $t('files').toUpper() :$t('your_preferences').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <preference-form :applicationData.sync="formData"
                                     @savePreferences="preferenceFormClose"
                                     v-if="formProcess=='preference'"
                    />
                    <list-of-files :documents="formData"
                                   @savePreferences="preferenceFormClose"
                                   v-if="formProcess=='showFiles'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';
import ListOfFiles from "@/modules/doubleMajor/application/ListOfFiles";

// Page
import PreferenceForm from './PreferenceForm';

// Service
import DoubleMajorService from '@/services/DoubleMajorService'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        Datatable,
        CommonModal,
        ListOfFiles,

        PreferenceForm
    },
    metaInfo() {
        return {
            title: this.$t('double_major_application')
        };
    },
    computed: {},
    data() {
        return {
            exportExcelData:{
                title:'EXCEL',
                show:this.checkPermission('doublemajor_excelexport'),
                emit:'exportExcel'
            },
            loadingPage: false,
            loadingApplications: false,

            isNewButton: false,

            showExtraStudentInfo: false,
            student: {},

            formId: null,
            formProcess: null,

            applications: [],

            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('your_preferences'),
                                class: 'ri-edit-box-line',
                                permission:'doublemajor_show',
                                callback: (row) => {
                                    this.preferenceFormShow(row);
                                },
                                show: (row) => {
                                    if (row.status == 'waiting_preference') {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            },
                            {
                                text: this.$t('edit_preference'),
                                class: 'ri-edit-box-line',
                                permission:'doublemajor_store',
                                callback: (row) => {
                                    this.preferenceFormShow(row);
                                },
                                show: (row) => {
                                    if (row.status == 'waiting_preference') {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission:'doublemajor_delete',
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                                show: (row) => {
                                    if (row.status == 'waiting_preference') {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            },
                            {
                                text: this.$t('document_show'),
                                class: 'ri-eye-line',
                                callback: (row) => {
                                    this.showListOfFiles(row.documents);
                                },
                                show: (row) => {
                                    if (row.status == 'waiting_preference') {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id').toUpper(),
                        field: 'id',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('period').toUpper() + ' / ' + this.$t('semester').toUpper(),
                        field: 'semester',
                        sortable: true,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.academic_year + ' / ' + this.getSemester(value);
                        }
                    },
                    {
                        label: this.$t('your_preferences').toUpper(),
                        field: 'double_major_preferences',
                        sortable: false,
                        hidden: false,
                        formatFn: (value) => {
                            let html = '';
                            if (value.length > 0) {
                                html += '<ol class="pl-2 m-0">'
                                value.forEach((item) => {
                                    html += '<li>' + item.faculty_name + '<br>' + item.program_name + '</li>'
                                })
                                html += '</ol>'
                            }

                            return html;
                        }
                    },
                    {
                        label: this.$t('result').toUpper(),
                        field: 'approved_faculty',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            if (row.approved_faculty && row.approved_program) {
                                return row.approved_faculty + '<br>' + row.approved_program;
                            } else {
                                return '-'
                            }
                        }
                    },
                    {
                        label: this.$t('prep_score').toUpper(),
                        field: 'prep_score',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('status').toUpper(),
                        field: 'status',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            let variant = 'badge-light';
                            if (value.includes('waiting')) variant = 'badge-warning';
                            else if (value == 'declined') variant = 'badge-danger';
                            else if (value == 'approved') variant = 'badge-success';

                            return '<span class="badge ' + variant + '">' + row.status_text + '</span>';
                        }
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    created() {
        this.getUser();
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            if(!this.checkPermission('doublemajor_index')) return
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            return DoubleMajorService.getAll()
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .then(() => {
                    this.isNewButton = this.datatable.rows.filter(item => item.status.includes('waiting')).length > 0 ? false : true;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        getUser() {
            this.loadingPage = true
            DoubleMajorService.studentForm()
                .then((response) => {
                    this.student = response.data.data;
                    this.getRows();

                })
                .catch((e) => {
                    this.$router.push('/404')
                })
                .finally(() => {
                    this.loadingPage = false
                })
        },

        store() {
            DoubleMajorService.store()
                .then((response) => {
                    let data = response.data.data;
                    this.preferenceFormShow(data);
                    this.getRows()

                })
                .catch((e) => {
                    this.showErrors(e);
                })
        },

        delete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        DoubleMajorService.del(id)
                            .then((response) => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                });
        },

        // Form Clear
        formClear() {
            this.formData = null
            this.formProcess = null
        },
        showListOfFiles(data) {
            this.formData = data
            this.formProcess = 'showFiles'
            this.$refs.preferenceFormModal.$refs.commonModal.show()
        },
        preferenceFormShow(data) {
            this.formData = data
            this.formProcess = 'preference'
            this.$refs.preferenceFormModal.$refs.commonModal.show()
        },
        preferenceFormClose() {
            this.$refs.preferenceFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        }
    }
}
</script>
